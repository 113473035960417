import {Alert, Button, Container, Dropdown, DropdownButton, Form, InputGroup} from 'react-bootstrap';
import React, {useEffect, useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import CustomerIdSelection from './CustomerIdSelection';
import MerchantIdSelection from './MerchantIdSelection';
import TenantIdSelection from './TenantIdSelection';
import {MerchantId} from '../../../types/MerchantId';
import {TenantId} from '../../../types/TenantId';
import {CustomerId} from '../../../types/CustomerId';
import {RoleId} from '../../../types/RoleId';
import {RoleType} from './types/RoleType';
import UserNameInput from './UserNameInput';
import fetchRoles from '../../../comnon/functions/FetchRoles';
import PasswordInput from '../../common/PasswordInput';
import {UserType} from '../../../types/UserType';
import {useNavigate} from 'react-router-dom';

const NewUser = () => {
    const [userType, setUserType] = useState<number>();
    const [merchant, setMerchant] = useState<MerchantId>(null);
    const [tenant, setTenant] = useState<TenantId>(null);
    const [customer, setCustomer] = useState<CustomerId>(null);
    const [error, setError] = useState<string | undefined>();
    const [rolesReady, setRolesReady] = useState(false);
    const [roles, setRoles] = useState<RoleType[]>([]);
    const [readyToCreate, setReadyToCreate] = useState<boolean>(false);
    const [role, setRole] = useState<RoleId>();
    const [userName, setUserName] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [token, setToken] = useState<string | undefined>(undefined);
    const [lang, setLang] = useState('de');


    const {getAccessTokenSilently, isLoading, isAuthenticated} = useAuth0();
    const navigate = useNavigate();


    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            setToken(undefined);
            getAccessTokenSilently().then((token) => {
                setToken(token);
            }).catch((error) => {
                console.error(error);
                setError(error);
            });
        }
    }, [isLoading, isAuthenticated]);

    const onLinkedEntityChanged = (e: { currentTarget: { value: string; }; }) => {
        let value = e.currentTarget.value;
        switch (userType) {
            case 1:
                setCustomer(value);
                break;
            case 2:
                setMerchant(value);
                break;
            case 3:
                setTenant(value);
                break;
            default:
                console.error('onChange of linkedEntity running with illegal userType');
                return '';
        }
    };

    const setLinkedEntityValue = (): string => {
        switch (userType) {
            case 1:
                return customer ? customer.toString() : '';
                break;
            case 2:
                return merchant ? merchant.toString() : '';
                break;
            case 3:
                return tenant ? tenant.toString() : '';
                break;
            default:
                console.error('Trying to set state of linkedEntity with illegal userType');
                return '';
        }
    };

    const createUser = () => {
        if (readyToCreate && role && userName) {
            //Yes, I know that neither role nor userName can be undefined here, but Typescript doesn't get it

            let bodyTemplate: UserType = {

                lang: lang,

                roleId: role,
                password: password,
                username: userName

            };
            switch (userType) {
                case 1:
                    // @ts-ignore
                    bodyTemplate.customerId = customer;
                    break;
                case 2:
                    // @ts-ignore
                    bodyTemplate.merchantId = merchant;
                    break;
                case 3:
                    // @ts-ignore
                    bodyTemplate.tenantId = tenant;
                    break;
                default:
                    console.error('Unexpected userType in createUser:', userType);
            }
            if (token) {
                fetch(`${process.env.REACT_APP_BASEURL}/user`, {
                    method: 'PUT',
                    credentials: 'include',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(bodyTemplate)
                }).then((res) => {
                    if (!res.ok) {
                        throw new Error(res.statusText);
                    } else {
                        return res.json();
                    }
                }).then((json) => {
                    navigate(`/users/userDetails/id/${json.userId}`);
                }).catch((error) => {
                    console.error(error);
                    setError(error);
                });

            } else {
                setError('Not ready to create! How did you even manage to call this function?!');
            }
        } else {
            setError('Not ready to create! How did you even manage to call this function?!');
        }

    };

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            setToken(undefined);
            getAccessTokenSilently().then((token) => {
                setToken(token);
            }).catch((err) => setError(JSON.stringify(err)));
        }
    }, [isLoading, isAuthenticated]);

    useEffect(() => {
            console.debug(role, userName, password, userType, customer, merchant, tenant,lang);
            if (role
                && token
                && userName
                && password
                && lang
                && (
                    (userType === 1 && customer)
                    || (userType === 2 && merchant)
                    || (userType === 3 && tenant)
                )) {
                setReadyToCreate(true);
            } else {
                setReadyToCreate(false);
            }
        }, [merchant, tenant, customer, userName, role, password, lang, token]
    );

    useEffect(() => {
        let abortController = new AbortController();
        let {signal} = abortController;
        if (token) {
            fetchRoles(token, signal).then((json) => {
                setRoles(json);
                setRolesReady(true);
            }).catch((error) => {
                console.error(error);
                setError(error);
            });
        } else {
            console.debug('deferring fetchRoles waiting for token');
        }
        return () => {
            abortController.abort();
        };

    }, [token]);


    return <Container className={'d-grid gap-3'}>
        {error && <Alert variant={'danger'}>{JSON.stringify(error)}</Alert>}
        <Form noValidate={true}>
            <Form.Label>Linked entity</Form.Label>
            <InputGroup hasValidation={true}>
                <DropdownButton
                    title={userType === 1 ? 'CUS' : userType === 2 ? 'MER' : userType === 3 ? 'TEN' : 'Select user type'}>
                    <Dropdown.Item id='tbg-btn-1' onClick={() => setUserType(1)}>
                        CUS
                    </Dropdown.Item>
                    <Dropdown.Item id='tbg-btn-2' onClick={() => setUserType(2)}>
                        MER
                    </Dropdown.Item>
                    <Dropdown.Item id='tbg-btn-3' onClick={() => setUserType(3)}>
                        TEN
                    </Dropdown.Item>
                </DropdownButton>

                {userType === undefined &&
                    <Form.Control className={'align-self-center'} disabled={true} value={'Please select entity type'}/>}

                {userType === 1 && <CustomerIdSelection onValueChanged={setCustomer}/>}

                {userType === 2 && <MerchantIdSelection onValueChanged={setMerchant}/>}

                {userType === 3 && <TenantIdSelection onValueChanged={setTenant}/>}
            </InputGroup>


            <UserNameInput onValueChanged={setUserName}/>
            <PasswordInput passwordChangedCallback={setPassword}/>
            <Form.Label htmlFor='roleSelect'>Role</Form.Label>
            <Form.Select disabled={!rolesReady} id='roleSelect' onChange={(e) => {
                setRole(e.currentTarget.value);
            }}>
                <option value={undefined} disabled={true} selected={true}>Select Role</option>
                {rolesReady && roles.map((item: RoleType, index: number) => <option key={index}
                                                                                    value={item.roleId}>{`${item.roleDescription} (${item.roleId})`}</option>)}
            </Form.Select>
            <Form.Label htmlFor={'languageSelect'}>Language</Form.Label>
            <Form.Select id='languageSelect' value={lang} onChange={(e) => setLang(e.target.value)}>
                <option value={'de'}>DE</option>
                <option value={'en'}>EN</option>
                <option value={'fr'}>FR</option>
                <option value={'it'}>IT</option>
            </Form.Select>
            <Button className={'mt-3'} variant={readyToCreate ? 'primary' : 'secondary'} disabled={!readyToCreate}
                    onClick={createUser}>Create</Button>

        </Form>
    </Container>;
    ;
};
export default NewUser;