import React, {useContext, useEffect, useState} from 'react';
import {Alert, Form, Spinner} from 'react-bootstrap';
import {useAuth0} from '@auth0/auth0-react';
import {TenantType} from './types/TenantType';
import {TenantId} from '../../../types/TenantId';

type Callback = (value: string) => void;

interface Props {
    onValueChanged: Callback;
}

const TenantIdSelection = ({initialValue,onValueChanged}: { initialValue?:TenantId, onValueChanged:(value:string)=>void }) => {
    const [tenants, setTenants] = useState<TenantType[]>([]);
    const [tenantsReady, setTenantsReady] = useState(false);
    const [error, setError] = useState<string>();
    const [invalid, setInvalid] = useState(false);
    const [token, setToken] = useState<string>();

    const {isAuthenticated, isLoading, getAccessTokenSilently} = useAuth0();

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            setToken(undefined);
            getAccessTokenSilently().then((token) => {
                setToken(token);
            }).catch((err) => setError(err));
        }
    }, [isLoading, isAuthenticated]);


    function fetchTenants(token: string, signal: AbortSignal) {
        return fetch(`${process.env.REACT_APP_BASEURL}/tenants`, {
            signal: signal,
            credentials: 'include',
headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then((res) => {
            if (!res.ok) {
                throw new Error(res.statusText);
            } else {
                return res.json();
            }
        });
    }

    useEffect(() => {
        const abortController = new AbortController();
        setTenantsReady(false);
        if (token) {
            let {signal} = abortController;
            fetchTenants(token, signal).then((json) => {
                setTenants(json);
                setTenantsReady(true);
            }).catch((error) => {
                console.error(error);
                setError(error);
            });
        } else {
            console.debug('Deferring fetchTenants waiting for token');
        }
        return () => {
            abortController.abort();
        };
    }, [token]);


    const handleValueChanged = (value: string) => {
        if (token) {
            (async () => {
                fetch(`${process.env.REACT_APP_BASEURL}/tenant/id/${value}`, {
                    credentials: 'include',
headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }).then((res) => {
                    console.log(res);
                    if (!res.ok) {
                        if (res.status === 404)
                            setInvalid(true);
                        else
                            throw new Error(res.status + ':' + res.statusText);
                    } else {
                        setInvalid(false);
                        onValueChanged(value);
                    }
                }).catch((error) => {
                    console.error(error);
                    setError(error);
                });
            })();
        } else {
            setError('No token');
        }


    };


    return (error ? <Alert variant={'danger'}>Could not load Tenant IDs: {JSON.stringify(error)}</Alert> :
            <>
                <Form.Control isInvalid={invalid}
                              readOnly={!tenantsReady}
                              value={initialValue !== null?initialValue:undefined}
                              placeholder={'Tenant'}
                              id={'tenant'} list={'dataListMerchant'}
                              onBlur={e => handleValueChanged(e.target.value)}/>
                {!tenantsReady && <Spinner animation={'border'}/>}
                <datalist id={'dataListMerchant'}>
                    <datalist id={'dataListMerchant'}>
                        {tenantsReady && tenants.map((item, index) => <option
                            key={index}
                            value={item.tenantId}>{`${item.tenantDescription} (${item.tenantId})`}</option>)}
                    </datalist>
                </datalist>
            </>
    );
};

export default TenantIdSelection;
