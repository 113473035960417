import React from 'react';
import {Accordion} from 'react-bootstrap';
import {MerchantType} from '../usermanagement/newUser/types/MerchantType';

const MerchantItem = ({merchant, index}:{merchant:MerchantType;index:number}) => {
    return (
        <Accordion.Item eventKey={index.toString()}>
            <Accordion.Header>{merchant.merchantId}</Accordion.Header>
            <Accordion.Body>{merchant.merchantDescription}</Accordion.Body>
        </Accordion.Item>

    );
};

export default MerchantItem;
