import React, {useEffect, useState} from 'react';
import {Accordion} from 'react-bootstrap';
import {TenantType} from '../usermanagement/newUser/types/TenantType';
import {useAuth0} from '@auth0/auth0-react';
import TenantItem from './TenantItem';

const TenantList = () => {
    const [tenants, setTenants] = useState<TenantType[]>();
    const [tenantsReady, setTenantsReady] = useState(false);
    const [token, setToken] = useState<string>();
    const [error, setError] = useState<string>();
    const {isAuthenticated, isLoading, getAccessTokenSilently} = useAuth0();

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            setToken(undefined);
            getAccessTokenSilently().then((token) => {
                setToken(token);
            }).catch((err) => setError(JSON.stringify(err)));
        }
    }, [isLoading, isAuthenticated]);

    function fetchTenants(token: string, signal: AbortSignal) {
        return fetch(`${process.env.REACT_APP_BASEURL}/tenants`, {
            signal: signal,
            credentials: 'include',
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then((res) => {
            if (!res.ok) {
                throw new Error(res.statusText);
            } else {
                return res.json();
            }
        });
    }

    useEffect(() => {
        const abortController = new AbortController();
        setTenantsReady(false);
        if (token) {
            let {signal} = abortController;
            fetchTenants(token, signal).then((json) => {
                setTenants(json);
                setTenantsReady(true);
            }).catch((error) => {
                console.error(error);
                setError(error);
            });
        } else {
            console.debug('Deferring fetchTenants waiting for token');
        }
        return () => {
            abortController.abort();
        };
    }, [token]);

    return (
        <Accordion>
            {tenants?.map((item,index)=><TenantItem tenant={item} index={index}/>)}
        </Accordion>
    );
};

export default TenantList;
