import {RoleViewType} from '../../../../types/RoleViewType';


export function fetchRoleViews(token: string | undefined, roleId: string | number): Promise<RoleViewType[]> {
    return fetch(`${process.env.REACT_APP_BASEURL}/role/${roleId}/views`, {
        credentials: 'include',
headers: {
            Authorization: `Bearer ${token}`,
        }
    }).then((result) => {
        if (result.ok) {
            return result.json();
        } else {
            throw new Error(`${result.status}: ${result.statusText}`);
        }
    });
}