import React, {useContext, useEffect, useState} from 'react';
import {Button, Form, InputGroup} from 'react-bootstrap';
import {useAuth0} from '@auth0/auth0-react';
import {CustomerType} from './types/CustomerType';
import {CustomerId} from '../../../types/CustomerId';

const CustomerIdSelection = ({initialValue,onValueChanged}:{initialValue?:CustomerId, onValueChanged:(value:string)=>void}) => {
    const [customerSearchTerm, setCustomerSearchTerm] = useState<string>('');
    const [searchResultsReady, setSearchResultsReady] = useState(false);
    const [error, setError] = useState<string | undefined | null>();
    const [customers, setCustomers] = useState<CustomerType[]>([]);
    const [token, setToken] = useState<string | undefined>(undefined);
    const [invalid, setInvalid] = useState(false);

    const {isAuthenticated, isLoading, getAccessTokenSilently} = useAuth0();

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            setToken(undefined);
            getAccessTokenSilently().then((token) => {
                setToken(token);
            }).catch((err) => setError(JSON.stringify(err)));
        }
    }, [isLoading, isAuthenticated]);

    useEffect(() => {
        if(initialValue)
            setCustomerSearchTerm(initialValue);
    }, []);



    const clearCustomerSearch = () => {
        setCustomerSearchTerm('');
        setSearchResultsReady(false);
    };


    const fetchCustomers = (search: string) => {
        console.debug('fetchCustomers');
        setInvalid(false);
        setSearchResultsReady(false);
        if (token) {
            (async () => {
                fetch(`${process.env.REACT_APP_BASEURL}/customer?search=${search}`, {
                    credentials: 'include',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }).then((res) => {
                    console.trace(res);
                    if (!res.ok) {
                        if (res.status === 404) {
                            setInvalid(true);
                            setSearchResultsReady(false);
                        }
                        else
                            throw new Error(res.status + ':' + res.statusText);
                    } else {
                        return res.json();
                    }
                }).then((json: CustomerType[]) => {
                    if (json?.length > 0) {
                        setCustomers(json);
                        setSearchResultsReady(true);
                    } else {
                        setSearchResultsReady(false);
                        setInvalid(true);
                    }
                }).catch((error) => {
                    console.error(error);
                    setError(error);
                });
            })();
        } else {
            setError('No token');
        }
    };

    return (
        <>

            {!searchResultsReady && <Form.Control
                id='customerSearch'
                className={'border-end-0'}
                required
                isInvalid={invalid}
                value={customerSearchTerm}
                placeholder='Customer'
                onChange={e => setCustomerSearchTerm(e.target.value)}/>

            }
            {searchResultsReady && <Form.Select onChange={(e)=>onValueChanged(e.currentTarget.value)}>
                <option disabled={true} selected={true}>Select one</option>
                {customers.map((item, index) => <option key={index} value={item.customerId}>{item.customerId}</option>)}
            </Form.Select>}
            <InputGroup.Text onClick={clearCustomerSearch}
                             className={'bg-white border-start-0 form-control-color'}>
                <i className={'btn-close'}/>
            </InputGroup.Text>
            <Button variant={'secondary'} onClick={() => fetchCustomers(customerSearchTerm)}>
                <i className={'bi bi-search'}/>
            </Button>
            <Form.Control.Feedback type={'invalid'}>No customer found for this query</Form.Control.Feedback>

        </>
    );
};

export default CustomerIdSelection;
