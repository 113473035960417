export const deleteUv = (userViewId: string, token: string | undefined, itemChangedCallback: () => void) => {
    fetch(`${process.env.REACT_APP_BASEURL}/userView/` + userViewId, {
        method: 'DELETE',
        credentials: 'include',
headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }).then((response:Response) => {
        if (response.ok) {
            itemChangedCallback();
        } else {
            throw new Error(response.status + ':' + response.statusText);
        }
    });
};