import React, {useEffect, useState} from 'react';
import {Button, ButtonGroup, FormControl, FormGroup, ListGroup, Spinner} from 'react-bootstrap';
import {useAuth0, User} from '@auth0/auth0-react';
import {fetchViews} from '../userViews/functions/FetchViews';
import {ViewType} from '../../../types/ViewType';
import {fetchUserContracts} from './functions/fetchUserContracts';
import {UserContractType} from '../../../types/UserContractType';
import UserContractItem from './UserContractItem';
import AddUserContract from './AddUserContract';



const UserContract: React.FC<{ userId: string}> = ({userId}) => {
    const [userContractsReady, setUserContractsReady] = useState(false);
    const [timedOut, setTimedOut] = useState(false);
    const [token, setToken] = useState<string | undefined>(undefined);
    const [err, setErr] = useState<string | undefined>(undefined);
    const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);
    const [userContracts, setUserContracts] = useState<UserContractType[]>([]);

    const {isAuthenticated, isLoading, getAccessTokenSilently} = useAuth0();

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            setToken(undefined);
            getAccessTokenSilently().then((token) => {
                setToken(token);
            }).catch((error) => {
                console.error(error);
                setErr(error);
            });
        }
    }, [isLoading, isAuthenticated]);

    useEffect(() => {
        const timeoutId = setTimeout(() => setTimedOut(true), 100000);
        setTimerId(timeoutId);
        return () => clearTimeout(timeoutId);
    }, []);

    function fetchUserContacts() {
        setUserContractsReady(false);
        if (token) {
            fetchUserContracts(token, userId)
                .then((userContracts: UserContractType[]) => {
                    console.debug(`Running setUserContracts with ${userContracts.length} entries`);
                    setUserContracts(userContracts);
                    setUserContractsReady(true);
                })
                .catch((error: string) => {
                    console.debug(`fetchUserContracts threw error ${error}`);
                    setErr(error);
                });
        }
    }

    useEffect(() => {
            fetchUserContacts();
        }
        , [token]);


    return (
        <>
            {!userContractsReady && !timedOut &&
                <div className={'d-flex align-items-center'}><Spinner animation={'border'} className={'me-3'}/>Loading
                    UserContracts...</div>}
            {!userContractsReady && timedOut &&
                `Unable to load UserContracts for User ${userId}`}
            {userContractsReady && <ListGroup>
                {userContracts.map((item, index) => <UserContractItem item={item} token={token}/>)}
                <ListGroup.Item>
                    <AddUserContract userId={userId} token={token} callback={fetchUserContacts} />
                </ListGroup.Item>
            </ListGroup>
            }
        </>
    );
};

export default UserContract;
