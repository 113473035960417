import copy from 'copy-to-clipboard';
import {useEffect, useState} from 'react';
import {Badge} from 'react-bootstrap';

const Clip = ({clip, className, designator}:{clip:string|undefined;className?:string;designator?:string})=>{
    const [copied, setCopied] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            setCopied(false)
        }, 1000);
        return () => clearTimeout(timer);
    }, [copied]);


    return <><i onClick={(e)=>{
        if(clip){
            copy(clip);
            setCopied(true);
            e.stopPropagation();
        }
        }} className={`bi ${copied? 'bi-check text-success':'bi-clipboard'} ${className}`} />
        {copied && <Badge pill bg='light' text='dark'>{`${designator?designator+' c':'C'}opied to clipboard`}</Badge>}
    </>
}
export default Clip