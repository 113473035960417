import React from 'react';

const MaybeDeleted = ({deleted, children}: { deleted: boolean, children: React.ReactNode }) => {
    return (deleted ?
            <del>
                {children}
            </del>
            :
            <>
                {children}
            </>
    );
};

export default MaybeDeleted;
