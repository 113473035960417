import {RoleType} from '../../components/usermanagement/newUser/types/RoleType';

const fetchRoles = (token:string,signal:AbortSignal) :Promise<RoleType[]>=> {

            return fetch(`${process.env.REACT_APP_BASEURL}/roles`, {
                signal: signal,
                credentials: 'include',
headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then((res) => {
                if (!res.ok) {
                    throw new Error(res.statusText);
                } else {
                    return res.json();
                }
            })

};

export default fetchRoles;