import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import {Auth0Provider} from '@auth0/auth0-react';

ReactDOM.render(
    <Auth0Provider
        domain="dev-icly.eu.auth0.com"
        clientId="VQRb5hVNp928o4AszDnjvi1VpU8EwjZh"
        redirectUri={window.location.origin}
        audience="https://api.internal.iclydev.ch"
    >
        <App />
    </Auth0Provider>,
    document.getElementById('root')
);
