import {Container, Nav, Navbar} from 'react-bootstrap';
import LoginComponent from '../login/LoginComponent';
import {useAuth0} from '@auth0/auth0-react';
import {useNavigate} from 'react-router-dom';

const Navigation = () => {
    const {isAuthenticated} = useAuth0();
    const navigate =useNavigate();
    return <Navbar bg='light'>

            <Navbar.Brand
                className={'d-flex align-items-center'}
                onClick={()=>navigate('/')}
            >
                <img alt='' className='mx-2' width='42px' src='../../logo_small.svg'/>
                <strong>IclyManager</strong>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
                {isAuthenticated && <Nav className="me-auto">
                    <Nav.Link
                        onClick={()=>navigate('/users')}
                        >Users</Nav.Link>
                    {process.env.NODE_ENV === 'development' &&<>
                    <Nav.Link
                    onClick={()=>navigate('/tenants')}
                    >Tenants</Nav.Link>
                    <Nav.Link
                        onClick={()=>navigate('/merchants')}
                    >Merchants</Nav.Link>
                    <Nav.Link
                        onClick={()=>navigate('/contracts')}
                    >Contracts</Nav.Link>
                    <Nav.Link
                        onClick={()=>navigate('/terminals')}
                    >Terminals</Nav.Link>
                        <Nav.Link onClick={()=>navigate('/issuing')}>Issuing</Nav.Link>
                    </>}
                </Nav>}
            </Navbar.Collapse>
            <LoginComponent/>

    </Navbar>;
};




export default Navigation;