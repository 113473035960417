import {Alert, Button, Container, Dropdown, DropdownButton, Form, InputGroup} from 'react-bootstrap';
import {KeyboardEvent, useEffect, useState} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';

const UserPage = () => {
    enum Searchmodes {
        SHARP = 'equals',
        CONTAINS = 'contains',
        PREFIX = 'prefix',
        SUFFIX = 'suffix',
        REGEX = 'regex',
        NONE = 'Mode'
    }

    const [radioValue, setRadioValue] = useState('Search by');
    const [searchterm, setSearchterm] = useState<string>('');
    const [searchmode, setSearchmode] = useState<Searchmodes>(Searchmodes.NONE);
    const [searchParametersValid, setSearchParametersValid] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (radioValue !== 'Search by' && (radioValue === 'ID' || searchmode !== Searchmodes.NONE))
            setSearchParametersValid(true);
        else
            setSearchParametersValid(false);
    }, [radioValue, searchmode]);


    const fetchUser = () => {
        if (searchterm) {
            if (radioValue === 'ID') {
                navigate(`userDetails/id/${searchterm}`);
            } else if (radioValue === 'Login') {
                if (Searchmodes.NONE !== searchmode) {
                    console.debug('Searchmode', searchmode, 'Radio:', radioValue);
                    if (radioValue === 'Login') {
                        navigate(`userList/name/${searchterm}?searchmode=${searchmode}`);
                    }
                } else {
                    console.debug('unknown searchmode', searchmode);
                }
            } else {
                console.debug('Searchterm set but not what it is');
            }
        } else {
            navigate('userList');
        }
    };

    return <Container className={'mt-3 '} >
        <Alert>{searchterm}</Alert>
        <Form className={'d-flex justify-content-center mb-3'}>
            <InputGroup>
                <DropdownButton variant={'outline-secondary'} title={radioValue}>
                    <Dropdown.Item
                        key={0}
                        id={`by-name`}
                        variant={'outline-secondary'}
                        onClick={() => setRadioValue('Login')}> Login </Dropdown.Item>
                    <Dropdown.Item
                        key={1}
                        id={`by-id`}
                        variant={'outline-secondary'}
                        onClick={() => setRadioValue('ID')}> ID </Dropdown.Item>
                </DropdownButton>

                {radioValue === 'Login' && <DropdownButton variant='outline-secondary' className={'mx-auto'}
                                                           title={searchmode}>
                    <Dropdown.Item onClick={() => setSearchmode(Searchmodes.SHARP)}>equals</Dropdown.Item>
                    <Dropdown.Item onClick={() => setSearchmode(Searchmodes.CONTAINS)}>contains</Dropdown.Item>
                    <Dropdown.Item onClick={() => setSearchmode(Searchmodes.PREFIX)}>starts with</Dropdown.Item>
                    <Dropdown.Item onClick={() => setSearchmode(Searchmodes.SUFFIX)}>ends with</Dropdown.Item>
                    <Dropdown.Item onClick={() => setSearchmode(Searchmodes.REGEX)}>matches</Dropdown.Item>
                </DropdownButton>}

                <Form.Control className={'border-end-0'} required value={searchterm} placeholder='Search term'
                              onChange={e => setSearchterm(e.target.value)} disabled={!searchParametersValid}/>
                <Button onClick={() => setSearchterm('')}
                                 className={'bg-transparent border-0 text-dark btn-close '} style={{ marginLeft: -40, zIndex:100, paddingTop:18,paddingRight:20}}>
                </Button>
                <Dropdown align={'end'}>
                    <Button variant={'outline-secondary'} onClick={fetchUser}>{searchParametersValid?'Search':'List'}</Button>
                    <Dropdown.Toggle variant={'outline-secondary'} split/>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => navigate('/newUser')}>Create new</Dropdown.Item>
                        {process.env.NODE_ENV === 'development'&&
                        <Dropdown.Item onClick={() => navigate('advancedSearch')}>Advanced Search</Dropdown.Item>}
                    </Dropdown.Menu>
                </Dropdown>


            </InputGroup>


        </Form>
        <Outlet/>
    </Container>;
};

export default UserPage;
