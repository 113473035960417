import 'bootstrap/dist/css/bootstrap.css';
import Navigation from './components/common/Navigation';
import RoutingManager from './components/common/RoutingManager';
import {BrowserRouter as Router} from 'react-router-dom';

function App() {

    return (
        <Router>
            <Navigation/>
            <RoutingManager/>
        </Router>
    );
}

export default App;
