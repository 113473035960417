import {ViewType} from '../../../../types/ViewType';

export const fetchViews = (token:string) :Promise<ViewType[]> => {
    return  fetch(`${process.env.REACT_APP_BASEURL}/views`, {
        credentials: 'include',
headers: {
            Authorization: `Bearer ${token}`,
        }
    }).then((result)=>{
        if(result.ok){
            return result.json()
        } else {
            throw new Error(`${result.status}: ${result.statusText}`)
        }
    });
}