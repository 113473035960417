import React, {useEffect, useState} from 'react';
import {Button, ButtonGroup, Card, Dropdown, DropdownButton, Form, InputGroup} from 'react-bootstrap';
import Clip from '../../common/Clip';
import {UserType} from '../../../types/UserType';
import {RoleType} from '../newUser/types/RoleType';
import CustomerIdSelection from '../newUser/CustomerIdSelection';
import MerchantIdSelection from '../newUser/MerchantIdSelection';
import TenantIdSelection from '../newUser/TenantIdSelection';
import {MerchantId} from '../../../types/MerchantId';
import {TenantId} from '../../../types/TenantId';
import {CustomerId} from '../../../types/CustomerId';
import {RoleId} from '../../../types/RoleId';
import {useAuth0} from '@auth0/auth0-react';
import {useNavigate} from 'react-router-dom';
import UserNameInput from '../newUser/UserNameInput';
import PasswordInput from '../../common/PasswordInput';
import fetchRoles from '../../../comnon/functions/FetchRoles';

const EditableUserData = ({user, userChangedCallback}: { user: UserType, userChangedCallback: () => void }) => {
    const [userType, setUserType] = useState<number>();
    const [merchant, setMerchant] = useState<MerchantId>(null);
    const [tenant, setTenant] = useState<TenantId>(null);
    const [customer, setCustomer] = useState<CustomerId>(null);
    const [error, setError] = useState<string | undefined>();
    const [rolesReady, setRolesReady] = useState(false);
    const [roles, setRoles] = useState<RoleType[]>([]);
    const [readyToCreate, setReadyToCreate] = useState<boolean>(false);
    const [role, setRole] = useState<RoleId>();
    const [userName, setUserName] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [token, setToken] = useState<string | undefined>(undefined);
    const [lang, setLang] = useState('de');

    const {getAccessTokenSilently, isLoading, isAuthenticated} = useAuth0();
    const navigate = useNavigate();


    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            setToken(undefined);
            getAccessTokenSilently().then((token) => {
                setToken(token);
            }).catch((error) => {
                console.error(error);
                setError(error);
            });
        }
    }, [isLoading, isAuthenticated]);

    const onLinkedEntityChanged = (e: { currentTarget: { value: string; }; }) => {
        let value = e.currentTarget.value;
        switch (userType) {
            case 1:
                setCustomer(value);
                break;
            case 2:
                setMerchant(value);
                break;
            case 3:
                setTenant(value);
                break;
            default:
                console.error('onChange of linkedEntity running with illegal userType');
                return '';
        }
    };

    const setLinkedEntityValue = (): string => {
        switch (userType) {
            case 1:
                return customer ? customer.toString() : '';
                break;
            case 2:
                return merchant ? merchant.toString() : '';
                break;
            case 3:
                return tenant ? tenant.toString() : '';
                break;
            default:
                console.error('Trying to set state of linkedEntity with illegal userType');
                return '';
        }
    };

    useEffect(() => {
        setUserName(user.username);
        setRole(user.roleId);
        setLang(user.lang);
        if (user.customerId) {
            setUserType(1);
            setCustomer(user.customerId);
        } else if (user.merchantId) {
            setUserType(2);
            setMerchant(user.merchantId);
        } else if (user.tenantId) {
            setUserType(3);
            setTenant(user.tenantId);
        }
    }, []);

    useEffect(() => {
        switch (userType) {
            case 1:
                setMerchant(null);
                setTenant(null);
                break;
            case 2:
                setCustomer(null);
                setTenant(null);
                break;
            case 3:
                setCustomer(null);
                setMerchant(null);
                break;
            default:
                console.error('Trying to set state of linkedEntity with illegal userType');
        }
    }, [userType]);


    useEffect(() => {
        let abortController = new AbortController();
        let {signal} = abortController;
        if (token) {
            fetchRoles(token, signal).then((json) => {
                setRoles(json);
                setRolesReady(true);
            }).catch((error) => {
                console.error(error);
                setError(error);
            });
        } else {
            console.debug('deferring fetchRoles waiting for token');
        }
        return () => {
            abortController.abort();
        };

    }, [token]);

    const checkCanSave = () => {

        return true;

    };

    const saveUser = () => {
        if (checkCanSave() && role) {
            //Yes, I know that neither role nor userName can be undefined here, but Typescript doesn't get it

            let bodyTemplate: UserType = {

                lang: lang,
                username:userName,
                roleId: role,
                password: password
            };
            switch (userType) {
                case 1:
                    // @ts-ignore
                    bodyTemplate.customerId = customer;
                    break;
                case 2:
                    // @ts-ignore
                    bodyTemplate.merchantId = merchant;
                    break;
                case 3:
                    // @ts-ignore
                    bodyTemplate.tenantId = tenant;
                    break;
                default:
                    console.error('Unexpected userType in createUser:', userType);
            }
            if (token) {
                fetch(`${process.env.REACT_APP_BASEURL}/user/${user.userId}`, {
                    method: 'PATCH',
                    credentials: 'include',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(bodyTemplate)
                }).then((res) => {
                    if (!res.ok) {
                        throw new Error(res.statusText);
                    } else {
                        return res.text();
                    }
                }).then((json) => {
                    userChangedCallback();
                }).catch((error) => {
                    console.error(error);
                    setError(error);
                });

            } else {
                setError('Not ready to create! How did you even manage to call this function?!');
            }
        } else {
            setError('Not ready to create! How did you even manage to call this function?!');
        }

    };


    const cancel = () => {
        userChangedCallback();
    };


    return (<Form className={'d-grid gap-2'}>
            <Card.Text>UserId {user.userId}<Clip clip={user.userId}/></Card.Text>

            <Form.Label htmlFor={'roleSelect'}>Role</Form.Label>
            <Form.Select disabled={!rolesReady} id='roleSelect' onChange={(e) => {
                setRole(e.currentTarget.value);
            }}>
                {rolesReady && roles.map((item: RoleType, index: number) => <option key={index}
                                                                                    value={item.roleId}
                                                                                    selected={item.roleId === user.roleId ? true : false}>{`${item.roleDescription} (${item.roleId})`}</option>)}
            </Form.Select>
            <Form.Label htmlFor={'linkSelect'}>Linked Entity</Form.Label>
            <InputGroup hasValidation={true}>
                <DropdownButton
                    title={userType === 1 ? 'CUS' : userType === 2 ? 'MER' : userType === 3 ? 'TEN' : 'Select user type'}>
                    <Dropdown.Item id='tbg-btn-1' onClick={() => setUserType(1)}>
                        CUS
                    </Dropdown.Item>
                    <Dropdown.Item id='tbg-btn-2' onClick={() => setUserType(2)}>
                        MER
                    </Dropdown.Item>
                    <Dropdown.Item id='tbg-btn-3' onClick={() => setUserType(3)}>
                        TEN
                    </Dropdown.Item>
                </DropdownButton>

                {userType === undefined &&
                    <Form.Control id='linkSelect' className={'align-self-center'} disabled={true} value={'Please select entity type'}/>}

                {userType === 1 && <CustomerIdSelection initialValue={customer} onValueChanged={setCustomer}/>}

                {userType === 2 && <MerchantIdSelection initialValue={merchant} onValueChanged={setMerchant}/>}

                {userType === 3 && <TenantIdSelection initialValue={tenant} onValueChanged={setTenant}/>}
            </InputGroup>

            <Form.Label htmlFor={'languageSelect'}>Language</Form.Label>
            <Form.Select id='languageSelect' value={lang} onChange={(e) => setLang(e.target.value)}>
                <option value={'de'}>DE</option>
                <option value={'en'}>EN</option>
                <option value={'fr'}>FR</option>
                <option value={'it'}>IT</option>
            </Form.Select>
            <UserNameInput initialValue={user.username} onValueChanged={setUserName}/>
            <PasswordInput passwordChangedCallback={setPassword}/>
            <ButtonGroup>
                <Button variant='primary' onClick={() => saveUser()}>Save</Button>
                <Button variant='danger' onClick={() => cancel()}>Cancel</Button>
            </ButtonGroup>
        </Form>

    );
};

export default EditableUserData;