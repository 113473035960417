import {ViewRoleType} from '../../../../types/ViewRoleType';
import {RoleViewType} from '../../../../types/RoleViewType';
import {CombinedViewItemType} from '../types/CombinedViewItemType';
import {UserViewType} from '../types/UserViewType';

export function fetchUserViews(token: string | undefined, userId: string, combinedViewItem: CombinedViewItemType): Promise<UserViewType[]> {
    return fetch(`${process.env.REACT_APP_BASEURL}/userView/${userId}/${combinedViewItem.viewId}`, {
        credentials: 'include',
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }).then((result) => {
        if (result.ok) {
            return result.json();
        } else {
            throw new Error(`${result.status}: ${result.statusText}`);
        }
    });
}