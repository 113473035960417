import React, {useState} from 'react';
import {Button, ButtonToolbar, Form, InputGroup} from 'react-bootstrap';

const Contracts = () => {
    const [valid, setValid] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const [validated, setValidated] = useState(false);
    const [cleartext, setCleartext] = useState(false);

    const toggleValid = () => setValid(!valid);
    const toggleInvalid = () => setInvalid(!invalid);
    const toggleValidated = () => {
        setValidated(!validated);
    };
    const toggleCleartext = ()=> setCleartext(!cleartext);

    return (
        <div className={'bg-dark m-3 p-3'}>
            <h4>ContractsPage</h4>
            <ButtonToolbar className={'m-3'}>
                <Button onClick={toggleValid}>{`Valid: ${valid}`}</Button>
                <Button onClick={toggleInvalid}>{`Invalid: ${invalid}`}</Button>
                <Button onClick={toggleValidated}>{`Validated: ${validated}`}</Button>
            </ButtonToolbar>
            <Form noValidate={true} validated={validated}>
                <InputGroup hasValidation={true}>
                    <InputGroup.Text>Pre</InputGroup.Text>
                    <Form.Control isInvalid={invalid} isValid={valid}/>
                    <Button onClick={toggleCleartext}><i
                        className={`bi ${cleartext ? 'bi-eye-slash' : 'bi-eye'}`}/></Button>
                    <Form.Control.Feedback type={'invalid'}>Feedback</Form.Control.Feedback>
                </InputGroup>
            </Form>

        </div>
    );
};

export default Contracts;
