import React from 'react';
import {Container, Form} from 'react-bootstrap';

const AdvancedUserSearch = () => {
    return (
        <Form>
Hello

        </Form>
    );
};

export default AdvancedUserSearch;
