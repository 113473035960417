import React, {useContext, useEffect, useState} from 'react';
import {Alert, Form, Spinner} from 'react-bootstrap';
import {useAuth0} from '@auth0/auth0-react';
import {MerchantId} from '../../../types/MerchantId';
import {MerchantType} from './types/MerchantType';


const MerchantIdSelection = ({initialValue, onValueChanged}: { initialValue?:MerchantId, onValueChanged: (value:string)=>void }) => {
    const [merchantsReady, setMerchantsReady] = useState(false);

    const [merchants, setMerchants] = useState<MerchantType[]>([]);
    const [internalError, setError] = useState<string | undefined>();
    const [invalid, setInvalid] = useState(false);
    const [token, setToken] = useState<string | undefined>(undefined);

    const {isAuthenticated, isLoading, getAccessTokenSilently} = useAuth0();

    useEffect(() => {
        if (!isLoading && isAuthenticated) {

            setToken(undefined);
            getAccessTokenSilently().then((token) => {
                setToken(token);
            }).catch((err) => setError(err));
        }
    }, [isLoading, isAuthenticated]);


    function fetchMerchants(token: string, signal: AbortSignal): Promise<MerchantType[]> {
        return fetch(`${process.env.REACT_APP_BASEURL}/merchants`, {
            signal: signal,
            credentials: 'include',
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then((res) => {
            if (!res.ok) {
                throw new Error(res.statusText);
            } else {
                return res.json();
            }
        });
    }

    useEffect(() => {
        const abortController = new AbortController();
        setMerchantsReady(false);
        if (token) {
            let {signal} = abortController;
            fetchMerchants(token, signal).then((json) => {
                setMerchants(json);
                setMerchantsReady(true);
            }).catch((error) => {
                console.error(error);
                setError(error);
            });

        } else {
            console.debug('Deferring fetchMerchants waiting for token');
        }

        return () => {
            abortController.abort();
        };
    }, [token]);

    const handleValueChanged = (value: string) => {
        if (token) {
            fetch(`${process.env.REACT_APP_BASEURL}/merchant/id/${value}`, {
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then((res) => {
                if (!res.ok) {
                    if (res.status === 404)
                        setInvalid(true);
                    else
                        throw new Error(res.status + ':' + res.statusText);
                } else {
                    setInvalid(false);
                    onValueChanged(value);
                }
            }).catch((error) => {
                console.error(error);
                setError(error);
            });

        } else {
            setError('No token');
        }


    };

    return internalError ? <Alert variant={'danger'}>Cannot load merchants: {JSON.stringify(internalError)}</Alert> :
        <>

            <Form.Control readOnly={!merchantsReady}
                          id={'merchant'}
                          list={'dataListMerchant'}
                          value={initialValue !== null?initialValue:undefined}
                          onBlur={event => handleValueChanged(event.target.value)}
                          className={'rounded-right'}
                          isInvalid={invalid}/>

            <Form.Control.Feedback type={'invalid'}>Not a valid merchant</Form.Control.Feedback>
            {!merchantsReady && <Spinner className={'mx-1'} animation={'border'}/>}
            <datalist id={'dataListMerchant'}>
                {merchantsReady && merchants.map((item, index) => <option
                    key={index}
                    value={item.merchantId}>{`${item.merchantDescription} (${item.merchantId})`}</option>)}
            </datalist>
        </>;
};

export default MerchantIdSelection;
