import React, {ChangeEvent, Suspense, useEffect, useState} from 'react';
import {Button, Col, Form, InputGroup, Row} from 'react-bootstrap';
import {PasswordFeedback} from 'react-password-strength-bar';

const PasswordInput = ({passwordChangedCallback}: { passwordChangedCallback: (password: string) => void }) => {
    const [password, setPassword] = useState<string>();
    const [confirmPassword, setConfirmPassword] = useState<string>();
    const [match, setMatch] = useState(true);
    const [cleartext, setCleartext] = useState(false);
    const [feedback, setFeedback] = useState<PasswordFeedback>();

    const PasswordStrengthBar = React.lazy(() => import('react-password-strength-bar'));


    const toggleCleartext = () => setCleartext(!cleartext);

    const handlePasswordChanged = () => {
        if (password && password !== confirmPassword) {
            console.debug(password, confirmPassword, 'don\'t match');
            setMatch(false);
        } else {
            console.debug(password, confirmPassword, 'match');
            setMatch(true);

            if (password) {
                console.log("running passowrdChangedCallback");
                passwordChangedCallback(password);
            }
        }
    };

    return (
        <Row>
            <Col>
                <Form.Label className={'mt-2'} htmlFor='password'>Password</Form.Label>
            </Col>
            <Col>
                <Form.Label className={'mt-2 ps-4'} htmlFor='confirmpassword'>Confirm Password</Form.Label>
            </Col>
            <Col xs={1}/>
            <InputGroup hasValidation={true}>
                <Form.Control
                    type={cleartext ? 'text' : 'password'}
                    id='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={handlePasswordChanged}
                    isInvalid={!match}
                />

                <Form.Control
                    type={cleartext ? 'text' : 'password'}
                    id='confpassword'
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                    onBlur={handlePasswordChanged}
                    isInvalid={!match}
                />
                <Button variant={'outline-secondary'} onClick={toggleCleartext}><i
                    className={`bi ${cleartext ? 'bi-eye-slash' : 'bi-eye'}`}/></Button>

                <Form.Control.Feedback type={'invalid'}>Passwords do not match</Form.Control.Feedback>
            </InputGroup>


        </Row>
    );
};

export default PasswordInput;
