export const createUserContract = (userId: string,  contractId: string | undefined, token: string | undefined, itemChangedCallback: () => void) => {
    if(token && contractId) {
        fetch(`${process.env.REACT_APP_BASEURL}/usercontracts/${userId}/contract/${contractId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            console.debug(response);
            if (response.ok) {
                itemChangedCallback();
            } else {
                throw new Error(response.status + ':' + response.statusText);
            }
        });
    }
};