import {Alert, ListGroup, Spinner} from 'react-bootstrap';
import React, {useEffect, useState} from 'react';
import ViewEntry from './ViewEntry';
import {combineViewLists} from './functions/CombineViewLists';
import {fetchViews} from './functions/FetchViews';
import {fetchRoleViews} from './functions/FetchRoleViews';
import {useAuth0} from '@auth0/auth0-react';
import {RoleViewType} from '../../../types/RoleViewType';
import {ViewType} from '../../../types/ViewType';
import {CombinedViewItemType} from './types/CombinedViewItemType';

interface UserViewProps {
    userId: string,
    roleId: string
}

const UserViews: React.FC<UserViewProps> = ({userId, roleId}: UserViewProps) => {
    const [err, setErr] = useState<string | null>();
    const [views, setViews] = useState<ViewType[] | null>(null);
    const [mix, setMix] = useState<CombinedViewItemType[]>([]);
    const [roleViews, setRoleViews] = useState<RoleViewType[] | null>();
    const [viewsReady, setViewsReady] = useState<boolean>(false);
    const [token, setToken] = useState<string>();
    const [timedOut, setTimedOut] = useState(false);
    const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);

    const {isAuthenticated, isLoading, getAccessTokenSilently} = useAuth0();

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            setToken(undefined);
            getAccessTokenSilently().then((token) => {
                setToken(token);
            }).catch((error) => {
                console.error(error);
                setErr(error);
            });
        }
    }, [isLoading, isAuthenticated]);

    useEffect(() => {
        const timeoutId = setTimeout(() => setTimedOut(true), 100000);
        setTimerId(timeoutId);
        return () => clearTimeout(timeoutId);
    }, []);


    useEffect(() => {
            if (token) {
                fetchViews(token)
                    .then((views: ViewType[]) => {
                        //console.debug(`Running setViews with ${views.length} entries`);
                        setViews(views);
                    })
                    .catch((error: string) => {
                        //console.debug(`fetchViews threw error ${error}`);
                        setErr(error);
                    });
            }
        }
        , [token]);

    useEffect(() => {
            if (token) {
                fetchRoleViews(token, roleId)
                    .then((roleViews: RoleViewType[]) => {
                        //console.debug(`Running setRoleViews with ${roleViews.length} entries`);
                        setRoleViews(roleViews);
                    })
                    .catch((error: string) => {
                        //console.debug(`fetchRoleViews threw error ${error}`);
                        setErr(error);
                    });

            }
        }
        , [token, userId]);

    useEffect(() => {
            //console.debug(`Running combineViewItem with ${views?.length} views and ${roleViews?.length} roleViews`);
            setViewsReady(false);
            if (views && views.length > 0 && roleViews && roleViews.length > 0) {
                let combinedList = combineViewLists(views, roleViews);
                if (combinedList) {
                    setMix(combinedList);
                    setViewsReady(true);
                }
            }

        },
        [views, roleViews]);


    return (<div className={'mx-3'}>
        {err && <Alert key={'0'} variant={'danger'}>{JSON.stringify(err)}</Alert>}
        {!viewsReady && !timedOut &&
            <div className={'d-flex align-items-center'}><Spinner animation={'border'} className={'me-3'}/>Loading
                Views...</div>}
        {!viewsReady && timedOut &&
            `Unable to load UserViews for User ${userId}`}
        {viewsReady && <ListGroup className={'mb-3'}>
            {mix.map((item, index) => <ViewEntry userId={userId} combinedViewItem={item} index={index}
                                                 itemChangedCallback={() => console.log('Callback!')}/>)}
        </ListGroup>}
    </div>);

};
export default UserViews;