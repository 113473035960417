import React from 'react';
import {TenantType} from '../usermanagement/newUser/types/TenantType';
import {Accordion} from 'react-bootstrap';

const TenantItem = ({tenant, index}:{tenant:TenantType;index:number}) => {
    return (
        <Accordion.Item eventKey={index.toString()}>
            <Accordion.Header>{tenant.tenantId}</Accordion.Header>
            <Accordion.Body>{tenant.tenantDescription}</Accordion.Body>
        </Accordion.Item>

    );
};

export default TenantItem;
