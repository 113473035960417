import {RoleViewType} from '../../../../types/RoleViewType';
import {ViewType} from '../../../../types/ViewType';
import {CombinedViewItemType} from '../types/CombinedViewItemType';

export function combineViewLists(views:ViewType[], roleViews:RoleViewType[]):CombinedViewItemType[] {
        if (views && roleViews) {
            const map:CombinedViewItemType[] = views.map((item) => {
                let roleView = roleViews.find(element => element.viewId === item.viewId);
                let retval:CombinedViewItemType = {...item, ...roleView};
                return retval;
            });
            return map;
        } else throw new Error("Input lists must be defined")
}