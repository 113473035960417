import React, {useContext, useEffect, useState} from 'react';
import {Alert, Form} from 'react-bootstrap';
import {useAuth0} from '@auth0/auth0-react';


const UserNameInput = ({
                           initialValue,
                           onValueChanged
                       }: { initialValue?: string; onValueChanged: (value: string | undefined) => void }) => {
    const [userName, setUserName] = useState<string>();
    const [invalid, setInvalid] = useState(false);
    const [error, setError] = useState<Error | undefined>();
    const [token, setToken] = useState<string | undefined>(undefined);

    const {isAuthenticated, isLoading, getAccessTokenSilently} = useAuth0();

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            setToken(undefined);
            getAccessTokenSilently().then((token) => {
                setToken(token);
            }).catch((err) => setError(err));
        }
    }, [isLoading, isAuthenticated]);

    useEffect(() => {
        setUserName(initialValue !== null ? initialValue : undefined);
    }, []);


    useEffect(() => {
        if (userName && userName?.length > 0 && userName !== initialValue) {
            handleUserNameChanged(userName);
        }
    }, [token]);

    useEffect(() => {
        if (userName && userName?.length > 0 && userName !== initialValue)
            handleUserNameChanged(userName);
    }, [userName]);

    const handleOnBlur = () => {
        if (userName && userName?.length > 0 && userName !== initialValue)
            handleUserNameChanged(userName);
    };


    const handleUserNameChanged = (value: string) => {
        if (value.length === 0) {
            console.debug('empty string, nothing to check');
            onValueChanged(undefined);
            return;
        }
        setError(undefined);
        if (token) {
            (async () => {
                fetch(`${process.env.REACT_APP_BASEURL}/user/username/${value}?searchmode=equals`, {
                    credentials: 'include',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }).then(async (res) => {
                    console.log(res);
                    if (!res.ok) {
                        if (res.status === 404) {
                            setInvalid(false);
                            onValueChanged(value);
                        } else {
                            const body = await res.text();
                            throw new Error(res.status + ':' + res.statusText + ':' + body);
                        }
                    } else {
                        setInvalid(true);
                        onValueChanged(undefined);
                    }
                }).catch((error) => {
                    console.dir(error);
                    console.log('Catching...');
                    setError(error);
                });
            })();
        } else {
            console.debug('deferring fetch users waiting for token');
        }
    };
    return (<>
            {error && <Alert variant={'danger'}>{`Error: ${error.message}`}</Alert>}

            <Form.Label htmlFor='username'>User name</Form.Label>
            <Form.Control id='username'
                          value={userName}
                          isInvalid={invalid}
                          onChange={(e) => setUserName(e.currentTarget.value)}
                          onBlur={() => handleOnBlur()}/>
            <Form.Control.Feedback type={'invalid'}>This Username is taken</Form.Control.Feedback>
        </>
    );
};

export default UserNameInput;
