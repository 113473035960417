import Home from '../../pages/Home';
import AdvancedUserSearch from '../usermanagement/advancedUserSearch/AdvancedUserSearch';
import TerminalsPage from '../../pages/TerminalsPage';
import SingleUser from '../usermanagement/singleUser/SingleUser';

import UserPage from '../../pages/Userpage';
import {MerchantsPage} from '../../pages/MerchantsPage';
import UserList from '../usermanagement/userList/UserList';
import Contracts from '../../pages/Contracts';
import NewUser from '../usermanagement/newUser/NewUser';
import TenantsPage from '../../pages/TenantsPage';

import {BrowserRouter as Router, Routes} from 'react-router-dom';
import {Route} from 'react-router';
import Issuing from '../../pages/Issuing';

const RoutingManager = () => {
    return (



            <Routes>
                <Route path='/' element={<Home/>}/>
                <Route path='/users' element={<UserPage/>}>
                    <Route path={'userList'} element={<UserList/>}>
                        <Route path={'name/:username'} element={<UserList/>}/>
                        <Route path={'id/:userId'} element={<UserList/>}/>
                    </Route>
                    <Route path={'userDetails'}>
                        <Route path={'name/:username'} element={<SingleUser/>}/>
                        <Route path={'id/:userId'} element={<SingleUser/>}/>
                    </Route>

                    <Route
                        path={'advancedSearch'}
                        element={<AdvancedUserSearch/>}/>
                </Route>
                <Route
                    path={'newUser'}
                    element={<NewUser/>}
                />
                <Route path='/tenants' element={<TenantsPage/>}/>
                <Route path='/merchants' element={<MerchantsPage/>}/>
                <Route path='/contracts' element={<Contracts/>}/>
                <Route path='/terminals' element={<TerminalsPage/>}/>
                <Route path='/issuing' element={<Issuing/>}/>

            </Routes>


    );
};

export default RoutingManager;