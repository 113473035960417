import {Form} from 'react-bootstrap';
import LoginButton from '../../../../../experiments/usermanagement-ts/src/components/login/LoginButton';
import {useAuth0} from '@auth0/auth0-react';
import LogoutButton from './LogoutButton';
import 'bootstrap-avatar/dist/avatar.css';

const LoginComponent: React.FC = () => {
    const {user, isAuthenticated} = useAuth0();

    return <Form className='d-flex gap-2 mx-3'>

        {isAuthenticated ?
            <div className={'d-flex align-items-center'}>
                <img alt='' className='avatar rounded-circle mx-1' src={user?.picture}/><span className={'mx-2'}>Logged in as: {user?.nickname}</span>
                <LogoutButton/>
            </div>
            :
            <div >
                <span className={'mx-2'}>Not logged in</span>
                <LoginButton/>
            </div>
        }

    </Form>;
};
export default LoginComponent;