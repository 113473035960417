import React, {useState} from 'react';
import {Button, ButtonGroup, FormControl} from 'react-bootstrap';
import {createUserContract} from './functions/createUserContract';

const AddUserContract: React.FC<{ userId: string,token:string|undefined,callback: ()=>void }> = ({userId,token,callback}) => {
    const [contractId, setContractId] = useState<string>();


    return (
        <ButtonGroup className={'mx-auto'}>
            <FormControl placeholder={'ContractId'} value={contractId} onChange={event => setContractId(event.currentTarget.value)}/>
            <Button onClick={()=>createUserContract(userId,contractId,token,callback)}>Add</Button>
        </ButtonGroup>

    );
};

export default AddUserContract;
