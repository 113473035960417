import {Badge, Button, Form, ListGroup, Spinner, Stack} from 'react-bootstrap';
import React, {useEffect, useState} from 'react';
import {fetchUserViews} from './functions/FetchUserViews';
import {createUserView} from './functions/CreateUserView';
import {CombinedViewItemType} from './types/CombinedViewItemType';
import {useAuth0} from '@auth0/auth0-react';
import {UserViewType} from './types/UserViewType';
import UserViewEntry from './UserViewEntry';

interface Props {
    userId: string;
    index: number;
    combinedViewItem: CombinedViewItemType;
    itemChangedCallback: (item: number) => void;
}

const ViewEntry = ({userId, index, combinedViewItem, itemChangedCallback}: Props) => {
    //console.debug('Entering ViewEntry', userId, index, combinedViewItem, itemChangedCallback);

    const [error, setError] = useState();
    const [userViews, setUserViews] = useState<UserViewType[]>();
    const [userViewItemsQueried, setUserViewItemsQueried] = useState(false);
    const [timedOut, setTimedOut] = useState<boolean>(false);
    const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);
    const [token, setToken] = useState<string>();
    const [canCreate, setCanCreate] = useState(false);

    const {isAuthenticated, isLoading, getAccessTokenSilently} = useAuth0();

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            setToken(undefined);
            getAccessTokenSilently().then((token) => {
                setToken(token);
            }).catch((error) => {
                console.error(error);
                setError(error);
            });
        }
    }, [isLoading, isAuthenticated]);

    useEffect(() => {
        let canCreate = combinedViewItem.viewStatus === true && userViewItemsQueried && userViews?.length == 0;
        setCanCreate(canCreate);
    }, [userViewItemsQueried, userViews]);

    function userViewDeleted(userViewId: string): void {
        setUserViews(userViews?.filter(item => item.userViewId !== userViewId));
    }


    function decideCreateButton() {
        return canCreate;
    }

    useEffect(() => {
        const timeoutId = setTimeout(() => setTimedOut(true), 100000);
        setTimerId(timeoutId);
        return () => clearTimeout(timeoutId);
    }, []);


    let loadUserViews = () => {
        if (token) {
            fetchUserViews(token, userId, combinedViewItem).then((userViews) => {
                //console.debug(`running setUserViews with ${userViews?.length} entries`);
                setUserViews(userViews);
                setUserViewItemsQueried(true);
            }).catch((error) => {
                console.error(error);
                setError(error);
            });
        }
    };
    useEffect(
        loadUserViews
        , [userId, token]);

    function userViewsCreatedCallback(): void {
        console.debug('UserViewCreatedCallback running');
        if (token) {
            fetchUserViews(token, userId, combinedViewItem).then((userViews) => {
                setUserViews(userViews);
                setUserViewItemsQueried(true);
            }).catch((error) => {
                console.error(error);
                setError(error);
            });
        }
    }


    return (<>


        <ListGroup.Item key={index} variant={combinedViewItem.viewStatus ? 'light' : 'danger'}>
            {error && <Form.Label>Error: {error}</Form.Label>}
            <Stack direction='horizontal' >
                <Form.Label
                    className='me-auto ml-3 text-truncate d-inline-block w-75'>{combinedViewItem.viewId + ': ' + combinedViewItem.viewDescription}</Form.Label>
                {combinedViewItem.roleViewId && combinedViewItem.roleViewStatus &&
                    <Badge bg='secondary' className='me-2'>Provided by Role</Badge>}
                {combinedViewItem.roleViewId && combinedViewItem.roleViewStatus === false &&
                    <Badge bg='warning' text='dark' className='me-2'>Deactivated in Role</Badge>}
                {combinedViewItem.viewStatus === false &&
                    <Badge bg='warning' text='dark' className='me-2'>View deactivated</Badge>}


                {decideCreateButton() &&
                    <Button
                        size='sm'
                        className={'mx-1'}
                        onClick={() => createUserView(userId, combinedViewItem.viewId, token, userViewsCreatedCallback)}
                        active={combinedViewItem.viewStatus}>
                        Create
                    </Button>}


            </Stack>
            {!userViews && !timedOut &&
                <div className={'d-flex align-items-center'}><Spinner animation={'border'} className={'me-3'}/>Loading
                    UserViews...</div>}
            {!userViews && timedOut &&
                <div>`Unable to load UserViews for ${combinedViewItem.viewId}`<i
                    className={'bi bi-arrow-counterclockwise'} onClick={loadUserViews}/></div>}
            {userViews && userViews?.length > 0 && <><Form.Label>UserView Entries:</Form.Label><ListGroup>
                {userViews.map((item, index) => {
                    return <UserViewEntry userView={item} key={index} index={index} userId={userId}
                                          itemDeletedCallback={userViewDeleted}/>;
                })
                }
            </ListGroup></>}

        </ListGroup.Item>
    </>);

};

export default ViewEntry;