import {Accordion, Button, ButtonGroup, Card, ListGroup, Spinner, Stack} from 'react-bootstrap';
import {useContext, useEffect, useState} from 'react';
import Alert from 'react-bootstrap/Alert';
import UserViews from '../userViews/UserViews';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import Clip from '../../common/Clip';
import {useAuth0} from '@auth0/auth0-react';
import {UserType} from '../../../types/UserType';
import UserContracts from '../userContract/UserContracts';
import Swal from 'sweetalert2';
import StaticUserData from './StaticUserData';
import EditableUserData from './EditableUserData';
import deleteUser from '../../../comnon/functions/DeleteUser';


const SingleUser = () => {

    const [searchParams] = useSearchParams();
    const {username, userId} = useParams();
    let searchmode = searchParams.get('searchmode');
    const navigate = useNavigate();

    console.debug('Params:', username, userId, searchmode);
    const [error, setError] = useState<string>();
    const [user, setUser] = useState<UserType>();
    const [token, setToken] = useState<string>();
    const [editMode, setEditMode] = useState(false);

    const {isAuthenticated, isLoading, getAccessTokenSilently} = useAuth0();

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            setToken(undefined);
            getAccessTokenSilently().then((token) => {
                setToken(token);
            }).catch((error) => {
                console.error(error);
                setError(error);
            });
        }
    }, [isLoading, isAuthenticated]);




    const getUser = () => {
        setError(undefined);
        console.debug('getUser running', token ? 'with token' : 'without token', 'and userid', userId, 'username', username, 'searchmode', searchmode);
        if (token) {
            let query = '';
            if (userId) {
                query = `${process.env.REACT_APP_BASEURL}/user/id/${userId}`;
            } else if (username && searchmode) {
                query = `${process.env.REACT_APP_BASEURL}/user/username/${username}?searchmode=${searchmode}`;
            } else {
                console.error('GetUser called, but state no good: ', userId, username, searchmode);
            }
            (async () => {
                fetch(query, {
                    credentials: 'include',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }).then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw response.statusText;
                    }
                }).then(
                    (json) => {
                        if (!json) {
                            throw new Error('null result returned');
                        } else if (json.length > 1) {
                            throw new Error('Non unique result');
                        } else if (json.length < 1) {
                            throw new Error('No results found');
                        } else {
                            setUser(json[0]);
                        }
                        console.log('What is in state user after call:', user);
                    }
                ).catch((error) => {
                    console.error('Catch:', JSON.stringify(error));
                    setError(error);
                });
            })();
        } else {
            console.debug('deferring getUser for missing token');
        }
    };

    useEffect(() => {
        console.debug('Fetching user, username', username, 'searchmode', searchmode, 'userId', userId);

        getUser();

    }, [token, username, searchmode, userId]);

    const handleEditCallback = ()=>{
        setEditMode(false);
        getUser();
    }


    return (<div className={'d-flex justify-content-center'}>
            <div className={'w-75'}>
                {error && <Alert variant={'danger'}>{error}</Alert>}
                {!user && !error && <div className={'d-flex justify-content-center align-items-stretch'}><Spinner animation={'border'} /><span className={'ms-1 h4 align-self-baseline'}>Fetching user</span></div>}

                {!error && user && <Card>
                    <Card.Header>
                        <Stack direction={'horizontal'}>
                            <Card.Text as='h4' className={'me-auto my-auto'}>Login: {user.username}
                                <Clip className={'m-1'} clip={user.username}/>
                            </Card.Text>
                            <i className={'bi bi-arrow-counterclockwise'} onClick={() => getUser()}/>
                        </Stack>
                    </Card.Header>
                    <Card.Body className={'d-grid gap-1'}>
                        {editMode?<EditableUserData user={user} userChangedCallback={handleEditCallback}/>:
                        <StaticUserData user={ user }/>}
                        {!editMode &&<ButtonGroup>
                            <Button variant='primary' onClick={()=>setEditMode(true)}>Edit</Button>
                            <Button variant='danger' onClick={() => deleteUser(token, user.userId,setError,()=>navigate('/user'))}>Delete</Button>
                        </ButtonGroup>}
                        <Accordion className={'w-100 mt-2'} style={{maxWidth:'100%'}} >
                            {user.userId && <Accordion.Item eventKey={'1'}>
                                <Accordion.Header>UserViews</Accordion.Header>
                                <Accordion.Body>
                                    <UserViews userId={user.userId} roleId={user.roleId}/>
                                </Accordion.Body>
                            </Accordion.Item>}
                        </Accordion>
                        <Accordion className={'w-100 mt-2'}>
                            {user.userId && <Accordion.Item eventKey={'1'}>
                                <Accordion.Header>UserContracts</Accordion.Header>
                                <Accordion.Body>
                                    <UserContracts userId={user.userId}/>
                                </Accordion.Body>
                            </Accordion.Item>}
                        </Accordion>

                    </Card.Body>
                </Card>}
            </div>
        </div>
    );

};

export default SingleUser;