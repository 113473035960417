import React from 'react';
import {Col, Container, Row, Tab} from 'react-bootstrap';
import Tree from 'react-animated-tree';


const IssuingPage = () => {
    return (
        <Container className={'mt-3'}>
            <Row>
                <Col xs={2}>
                    <Tree content={"Hello"} type={'TEN'} >
                        <Tree content={"One"} type={'MER'}/>
                        <Tree content={"Two"}  type={'MER'}/>
                        <Tree content={'Three'} type={'MER'}>
                            <Tree content={'V01'} type={'TRM'}/>
                            <Tree content={'V02'} type={'TRM'}/>
                        </Tree>
                    </Tree>
                    </Col>
                <Col></Col>
            </Row>
        </Container>
    );
};

export default IssuingPage;
