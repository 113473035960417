import Swal from 'sweetalert2';
import {useNavigate} from 'react-router-dom';


const deleteUser: (token: string | undefined, userId: string | undefined, errorCallback: (error: string) => void, successCallback: () => void) => void = (token, userId, errorCallback, successCallback) => {
    Swal.fire({
        title: 'Warning',
        icon: 'warning',
        html: `<div class='d-inline-flex flex-column align-items-center'><p>This deletes the user together with all his rights, contract associations and sessions. This can not be undone.</p><label for='inputConfirm' class='mb-1 text-muted text-secondary'>Type 'delete it' below to confirm</label><input type='text' id='inputConfirm'/></div>`,
        preConfirm: () => {
            // @ts-ignore
            const confirm = Swal.getPopup().querySelector('#inputConfirm').value;
            if (confirm !== 'delete it') {
                Swal.showValidationMessage(`Please enter 'delete it' in the text field`);
            }

        },
        showConfirmButton: true,
        confirmButtonText: 'Continue deleting',
        confirmButtonColor: 'tomato',
        showDenyButton: true,
        denyButtonText: 'Abort deleting this user',
        denyButtonColor: 'darkseagreen'
    }).then((result) => {
        if (result.isConfirmed)
            doActualDelete(token, userId, errorCallback, successCallback);
        else
            console.log('deletion aborted');
    });
};

const doActualDelete: (token: string | undefined, userId: string | undefined, errorCallback: (error: string) => void, successCallback: () => void) => void = (token, userId, errorCallback, successCallback) => {
    if (token && userId) {
        fetch(`${process.env.REACT_APP_BASEURL}/user/${userId}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then((res) => {
            if (!res.ok) {
                throw new Error(res.statusText);
            } else {
                return res.text();
            }
        }).then((json) => {
            Swal.fire({
                title: 'Deleted',
                icon: 'success',
                text: 'The user was deleted successfully',
                showCloseButton: true
            }).then(successCallback);
        }).catch((error) => {
            console.error(error);
            errorCallback(error);
        });

    } else {
        errorCallback(`Must have token and userId to delete, ${token ? 'have token' : 'don\'t have token'}, ${userId ? 'have userId' : 'don\'t have userId'}`);
    }
};

export default deleteUser;