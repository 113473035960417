import {UserContractType} from '../../../../types/UserContractType';

export const fetchUserContracts = (token:string,userId:string) :Promise<UserContractType[]> => {
    return  fetch(`${process.env.REACT_APP_BASEURL}/usercontracts/${userId}`, {
        credentials: 'include',
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }).then((result)=>{
        if(result.ok){
            return result.json()
        } else {
            throw new Error(`${result.status}: ${result.statusText}`)
        }
    });
}