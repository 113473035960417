import React from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {Outlet} from 'react-router-dom';

const Home = () => {
    const {isAuthenticated} = useAuth0();

    return (
        <div className={'d-flex align-items-center justify-content-center vh-100'}>
            <div>
                <h1 className={'text-center font-weight-bold display-1'}> Welcome to IclyManager</h1>
                {!isAuthenticated && <h4 className={'text-center display-5'}>You have to be logged in to use this application</h4>}
            </div>
            <Outlet/>
        </div>
    );
};

export default Home;
