import React from 'react';
import {Card} from 'react-bootstrap';
import Clip from '../../common/Clip';
import {UserType} from '../../../types/UserType';

const StaticUserData = ({user}: { user: UserType }) => {
    return (<>
            <Card.Text>UserId {user.userId}<Clip clip={user.userId}/></Card.Text>
            <Card.Text>Role: {user.roleId}</Card.Text>
            {user.tenantId &&
                <Card.Text>Tenant: {user.tenantId}<Clip className={'m'} clip={user.tenantId}/></Card.Text>}
            {user.merchantId &&
                <Card.Text>Merchant: {user.merchantId}<Clip className={'ms-1'} clip={user.merchantId}/></Card.Text>}
            {user.customerId &&
                <Card.Text>Customer: {user.customerId}<Clip className={'ms-1'} clip={user.customerId}/></Card.Text>}
            <Card.Text>Lang: {user.lang}</Card.Text>
        </>
    );
};

export default StaticUserData;
