import {useContext, useEffect, useState} from 'react';
import Alert from 'react-bootstrap/Alert';
import UserListItem from './UserListItem';
import {Accordion, Spinner} from 'react-bootstrap';
import {useParams, useSearchParams} from 'react-router-dom';
import {useAuth0} from '@auth0/auth0-react';
import {UserType} from '../../../types/UserType';

const UserList = () => {
    const {username, userId} = useParams();
    const [users, setUsers] = useState<UserType[]>([]);
    const [usersReady, setUsersReady] = useState(false);
    const [err, setErr] = useState<string>();
    const [timedOut, setTimedOut] = useState<boolean>(false);
    const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);


    const [searchParams] = useSearchParams();
    let searchmode = searchParams.get('searchmode');

    const [token, setToken] = useState<string>();

    const {isAuthenticated, isLoading, getAccessTokenSilently} = useAuth0();

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            setToken(undefined);
            getAccessTokenSilently().then((token) => {
                setToken(token);
            }).catch((err) => setErr(err));
        }
    }, [isLoading, isAuthenticated]);

    useEffect(() => {
        const timeoutId = setTimeout(() => setTimedOut(true), 30000);
        setTimerId(timeoutId);
        return () => clearTimeout(timeoutId);
    }, []);

    const fetchUsers = () => {
        console.debug('Fetching users:', token, userId, username, searchmode);
        setUsersReady(false);
        if (token) {
            let query = '';
            if (userId) {
                query = `${process.env.REACT_APP_BASEURL}/user/id/${userId}?searchmode=${searchmode}`;
            } else if (username && searchmode) {
                query = `${process.env.REACT_APP_BASEURL}/user/username/${username}?searchmode=${searchmode}`;
            } else {
                query = `${process.env.REACT_APP_BASEURL}/users`;
            }
            (async () => {
                const res = await fetch(query, {
                    credentials: 'include',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                });
                if (res.ok) {
                    const users: UserType[] = await res.json();
                    console.log('Users:', users);
                    setUsers(users);
                    setUsersReady(true);
                    setErr(undefined);
                } else if (res.status === 404) {
                    setUsersReady(true);
                    setErr(undefined);
                } else {
                    setErr(res.statusText);
                }
            })();
        } else {
            console.debug('Deferring fetchUsers for missing token');
        }
    };
    useEffect(() => {
            if (!isLoading && isAuthenticated) {
                fetchUsers();
            }
        }
        , [token, username, userId, searchmode]);

    function userDeleted(userId: string): void {
        //setUsers(users?.filter(item => item.userId !== userId));
        fetchUsers();
    }

    return (<div className={'d-flex justify-content-center'}>
            <div className={'w-50'}>
                {err && <Alert variant='danger'>Error loading users</Alert>}
                {!usersReady && !timedOut && <Spinner animation={'border'} className={'mx-auto'}/>}
                {!usersReady && timedOut && <Alert variant={'danger'}>{`Failed to load users within two minutes`}</Alert> }
                {usersReady && users?.length === 0 && <Alert variant={'secondary'}>{`No results found for query`}</Alert> }

                {users?.length > 0 && <Accordion>
                    {
                        users.map((user, index) => {
                            return (
                                <Accordion.Item eventKey={index.toString()} key={index}>
                                    <UserListItem user={user} errorCallback={setErr} itemDeletedCallback={userDeleted}/>
                                </Accordion.Item>

                            );
                        })
                    }
                </Accordion>}
            </div>
        </div>
    );

};

export default UserList;