import {Badge, Button, Stack} from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import {useNavigate} from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Clip from '../../common/Clip';
import {UserType} from '../../../types/UserType';
import {useAuth0} from '@auth0/auth0-react';
import React, {useEffect, useState} from 'react';
import deleteUser from '../../../comnon/functions/DeleteUser';
import MaybeDeleted from '../../common/MaybeDeleted';


const UserListItem = ({
                          user,
                          errorCallback,
                          itemDeletedCallback
                      }: { user: UserType, errorCallback: (error: string) => void, itemDeletedCallback: (userId: string) => void }) => {

    const [token, setToken] = useState<string | undefined>(undefined);
    const [deleted, setDeleted] = useState(false);


    const navigate = useNavigate();
    const {isAuthenticated, isLoading, getAccessTokenSilently} = useAuth0();

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            setToken(undefined);
            getAccessTokenSilently().then((token) => {
                setToken(token);
            }).catch((err) => console.log(err));
        }
    }, [isLoading, isAuthenticated]);
    return <>
        <Accordion.Header>
            <Stack className={'w-100'} direction={'horizontal'}>
                <Stack direction={'horizontal'} className={'w-100'}>
                    {deleted ? <del className={'me-1 my-auto text-muted'}>{user.username}</del> :
                        <p className={'me-1 my-auto'}>{user.username}</p>}<Clip clip={user.username}/>
                </Stack>
                {deleted && <Badge bg='danger' className={'me-2'}>Deleted</Badge>}

            </Stack>
        </Accordion.Header>
        <Accordion.Body>
            <MaybeDeleted deleted={deleted}>
                <p>Role: {user.roleId}</p>
                {user.tenantId && <p>Tenant: {user.tenantId}<Clip className={'m-1'} clip={user.tenantId}/></p>}
                {user.merchantId && <p> Merchant: {user.merchantId}<Clip className={'m-1'} clip={user.merchantId}/></p>}
                {user.customerId && <p> Customer: {user.customerId}<Clip className={'m-1'} clip={user.customerId}/></p>}
                <p> UserId: {user.userId}<Clip className={'m-1'} clip={user.userId}/></p>
            </MaybeDeleted>
            {!deleted && <>
                <Button onClick={() => navigate(`/users/userDetails/id/${user.userId}`)} className={'me-2'}
                        variant='primary'>Details</Button>
                <Button variant='danger'
                        onClick={() => deleteUser(token, user.userId, errorCallback, () => {
                            setDeleted(true);
                            const userId = user.userId as string;
                            itemDeletedCallback(userId);
                        })}>Delete</Button>
            </>}
        </Accordion.Body>
    </>;
//userId, userName, roleId, tenantId, merchantId, customerId, languageCode
};

export default UserListItem;