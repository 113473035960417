import React, {useEffect, useState} from 'react';
import {Badge, Button, ButtonGroup, ListGroup, Stack} from 'react-bootstrap';
import {UserViewItemType} from './types/UserViewItemType';
import {useAuth0} from '@auth0/auth0-react';
import {deleteUv} from './functions/DeleteUserView';
import Swal from 'sweetalert2';
import {UserViewType} from './types/UserViewType';
import userViews from './UserViews';
import Clip from '../../common/Clip';

interface Props {
    userView: UserViewType;
    index: number;
    userId: string;
    itemDeletedCallback: (userViewId: string) => void;

}

const UserViewEntry = ({userView, itemDeletedCallback}: Props) => {
    const [error, setError] = useState<string>();
    const [token, setToken] = useState<string>();
    const [deleted, setDeleted] = useState(false);
    const [active, setActive] = useState(false);

    const {isAuthenticated, isLoading, getAccessTokenSilently} = useAuth0();

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            setToken(undefined);
            getAccessTokenSilently().then((token) => {
                setToken(token);
            }).catch((error) => {
                console.error(error);
                setError(error);
            });
        }
    }, [isLoading, isAuthenticated]);

    useEffect(() => {
        setActive(userView.userViewStatus);
    }, [userView]);


    function decideDeactivate() {
        return !deleted && active;
    }

    function decideActivate() {
        return !deleted && !active;
    }

    function decideDelete() {
        return !deleted;
    }

    const changeActiveState = (userViewId: string, activeState: boolean) => {
        fetch(`${process.env.REACT_APP_BASEURL}/userView/` + userViewId, {
            method: 'PATCH',
            credentials: 'include',
headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({...userView, userViewStatus: activeState})

        }).then((response) => {
            //console.debug(response);
            if (response.ok) {
                userView.userViewStatus = activeState;
                setActive(activeState);
            } else {
                throw new Error(response.status + ':' + response.statusText);
            }
        }).catch((error) => Swal.fire({
            title: 'Uh-Oh! Something went wrong',
            icon: 'error',
            text: error,
            showCloseButton: true
        }));
    };


    const deactivate = (userViewId: string) => changeActiveState(userViewId, false,);

    const activate = (userViewId: string) => changeActiveState(userViewId, true);

    return (
        <ListGroup.Item variant={deleted ? 'secondary' : active ? 'light' : 'danger'}>
            <Stack className={'d-flex align-items-center'} direction={'horizontal'}>
                <Stack direction={'horizontal'} className={'me-auto my-auto'}>
                    {deleted ? <del className={'me-1 my-auto text-muted'}>{userView.userViewId}</del> :
                        <p className={'me-1 my-auto'}>{userView.userViewId}</p>}<Clip clip={userView.userViewId}/>
                </Stack>
                {deleted && <Badge bg='danger'>Deleted</Badge>}
                <ButtonGroup>
                    {decideActivate() &&
                        <Button
                            size='sm'
                            onClick={() => activate(userView.userViewId)}
                            active={userView.userViewStatus === false}>
                            Activate
                        </Button>}
                    {decideDeactivate() &&
                        <Button
                            size='sm'
                            onClick={() => deactivate(userView.userViewId)}>
                            Deactivate
                        </Button>
                    }
                    {decideDelete() && <Button
                        size='sm'
                        variant={'danger'}
                        onClick={() => deleteUv(userView.userViewId, token, () => {
                            setDeleted(true);
                            itemDeletedCallback(userView.userViewId);
                        })}>
                        Delete
                    </Button>
                    }
                </ButtonGroup>
            </Stack>
        </ListGroup.Item>
    );
};

export default UserViewEntry;
