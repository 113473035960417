import React from 'react';
import {UserContractType} from '../../../types/UserContractType';
import {Badge, Button, ButtonGroup, Form, ListGroup, Stack} from 'react-bootstrap';
import {deleteUv} from '../userViews/functions/DeleteUserView';
import {deleteUserContract} from './functions/deleteUserContract';


const UserContractItem: React.FC<{ item:UserContractType, token:string|undefined }> = ({item,token}) => {
    const decideActivate = () => !item.contractViewStatus;
    const decideDeactivate = () => item.contractViewStatus;
    const decideDelete = () => item.userContractId;
    const activate = () => {}
    const deactivate =() => {}


    return (
        <ListGroup.Item>
            <Stack direction={'horizontal'}>
                <Form.Label
                    className='me-auto ml-3 text-truncate'>{item.contractId}</Form.Label>
                <ButtonGroup>
                    {decideActivate() &&
                        <Button
                            size='sm'
                            onClick={() => activate()}>
                            Activate
                        </Button>}
                    {decideDeactivate() &&
                        <Button
                            size='sm'
                            onClick={() => deactivate()}>
                            Deactivate
                        </Button>
                    }
                    {decideDelete() && <Button
                        size='sm'
                        variant={'danger'}
                        onClick={() => deleteUserContract(item.userContractId,token,()=>console.debug(`UserContract ${item.userContractId} deleted`))}>
                        Delete
                    </Button>
                    }
                </ButtonGroup>
            </Stack>
        </ListGroup.Item>
    );
};

export default UserContractItem;
